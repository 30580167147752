<!-- Layout -->
<template>
    <div class="pn_bg" :class="{ show: open }">
        <div id="pn">
            <h5>{{ editTitle }}</h5>
            <slot />
        </div>
        <div class="close_bg" @click="closePn"></div>
    </div>
</template>
<script>
export default {
    name: 'Panel',
    data: () => ({}),
    props: {
        open: Boolean,
        editTitle: String,
    },
    mounted() {},
    methods: {
        closePn() {
            this.$emit('closePn');
        },
    },
};
</script>
<style lang="scss">
.pn_bg {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: opacity 0.5s;
    opacity: 0;
    z-index: -1000;
    &.show {
        opacity: 1;
        z-index: 100;
        #pn {
            transform: translate(0, 0);
        }
    }
}

.close_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 200px);
}

#pn {
    position: fixed;
    height: 200px;
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
    transition: all 0.3s;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #f8f4f1;
    padding: 24px;
    h5 {
        text-align: left;
        margin-bottom: 30px;
    }
}
</style>
