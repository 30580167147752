<template>
    <!-- -->
    <div
        v-if="data.show"
        class="b_item d_flex"
        :style="{ 'background-image': `url(${bgUrl})` }"
    >
        <div class="name">
            <span>{{ data.alias }}</span>
            <font :class="data.gender" />
            <button name="setting" @click="editHandler" />
        </div>
        <div class="detail_wrapper d_flex">
            <span>{{ age }}</span>
            <span>{{ data.breed }}</span>
            <span>{{ data.isNeutered ? '已結紮' : '未結紮' }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PetItem',
    components: {},
    props: {
        data: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },
    data: () => ({
        age: '',
    }),
    mounted() {},
    created() {
        if (this.data.birthday) {
            var birthday = new Date(this.data.birthday);
            var small_year = birthday.getFullYear();
            var small_month = birthday.getMonth();
            var now = new Date();
            var big_year = now.getFullYear();
            var big_month = now.getMonth();
            var year = big_year - small_year;
            var month = big_month - small_month;
            if (month < 0) {
                month += 12;
                year -= 1;
            }
            if (year == 0) {
                this.age += `${month}個月`;
            } else {
                this.age += `${year}歲${month}個月`;
            }
        }
    },
    computed: {
        bgUrl() {
            if (this.data.imgSrc) {
                return this.data.imgSrc;
            } else {
                if (this.index % 2 === 0) {
                    return require('@/assets/images/imgCard02.jpg');
                } else {
                    return require('@/assets/images/imgCard01.jpg');
                }
            }
        },
    },
    methods: {
        editHandler() {
            this.$emit('editHandler');
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.b_item {
    position: relative;
    height: 170px;
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .name {
        width: 100%;
        height: 24px;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
        text-align: left;
        * {
            vertical-align: top;
        }
        span {
            display: inline-block;
            line-height: 24px;
        }
        font,
        button {
            display: inline-block;
            height: 24px;
            width: 15px;
            margin-left: 6px;
            background-repeat: no-repeat;
            background-position: center;
            // background-size: cover;
            &.male {
                background-image: url(~@/assets/images/icMale.svg);
            }
            &.female {
                background-image: url(~@/assets/images/icFemale.svg);
            }
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 50px;
            background-image: url(~@/assets/images/icMore.svg);
        }
    }

    .detail_wrapper {
        width: 100%;
        flex-direction: row;
        justify-content: start;
        padding: 8px 12px;
        position: absolute;
        width: 100%;
        height: 52px;
        left: 0px;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) -48.86%,
            rgba(0, 0, 0, 0) 100%
        );
        align-items: center;

        span {
            padding: 0 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            color: #fff;
            border-right: 1px solid #fff;
            &:last-child {
                border: none;
            }
        }
    }

    * {
        font-size: 14px;
        line-height: 1.4;
    }
}
</style>
